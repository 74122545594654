<template>
	<div class="card">
		<div class="list-assigned__header">
			<div>
				<p>Assigned Routes</p>

				<div class="btns" v-if="!edit">
					<button class="add btn" @click="open">+ Add routes</button>
					<button class="edit btn" @click="edit = true">Edit</button>
				</div>
				<button class="btn btn-primary" v-if="edit" @click="edit = false">
					Done
				</button>
			</div>
		</div>
		<div class="routes">
			<div v-for="route in routes" :key="route.id">
				<div class="route-box" v-if="route?.pickup">
					<p>
						<span v-if="route.route_code">{{ `${route.route_code} - ` }}</span>
						{{ `${route.pickup}` }}
					</p>

					<img
						src="@/assets/img/close-icon.svg"
						class="icon"
						v-if="edit"
						@click="deleteRoute(route.id, 'pickup')"
						/>
				</div>

				<div class="route-box" v-if="route?.dropoff">
					<p>
						<span v-if="route.route_code">{{ `${route.route_code} - ` }}</span>
						{{ `${route.dropoff}` }}
					</p>

					<img
						src="@/assets/img/close-icon.svg"
						class="icon"
						v-if="edit"
						@click="deleteRoute(route.id, 'dropoff')"
						/>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import eventServiceInstance from '../../api/event_service'
import EventEmitter from '../../helpers/EventEmitter'
export default {
  data () {
    return {
      edit: false,
      routes: [
        // {
        //   route:
        //     'RCCG/LP 12-A Redeemed church Dominion Parish, Redemption camps',
        //   id: '1',
        // },
        // {
        //   route:
        //     'RCCG/LP 12-A Redeemed church Dominion Parish, Redemption camps',
        //   id: '2',
        // },
      ]
    }
  },

  mounted () {
    this.fetchAddAssignRoutes()

    EventEmitter.$on('fetch-routes', (data) => {
      this.fetchAddAssignRoutes()
    })
  },
  methods: {
    open () {
      this.$bvModal.show('eventRouteModal')
    },

    fetchAddAssignRoutes () {
      const eventId = this.$route.params.eventId

      eventServiceInstance
        .getEventRoutes(eventId)
        .then((res) => {
          this.routes = res.data

          
        })
        .catch((e) => {
        })
    },
    deleteRoute (id, action) {
      const routes = this.routes.filter((r) => r.id !== id)
      const currentRoute = this.routes.find((r) => r.id == id)

      if (action == 'pickup') {
        delete currentRoute.pickup
      } else {
        delete currentRoute.dropoff
      }
      this.routes = [...routes, currentRoute]
    }
  }
}
</script>
<style lang="scss" scoped>
* {
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;
}
.list-assigned__header {
  width: 100%;
  border-bottom: 1px solid #e5e9f2;
  margin-bottom: 20px;
}

.list-assigned__header div {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 2px 9px;
  height: 55px;
}

.list-assigned__header div button {
  padding: 4px 12px;
}

.list-assigned__header div p {
  flex: 2;
}

.list-assigned__header div .edit {
  background: #4848ed;
  color: #fff;
}

.list-assigned__header div .add {
  border: 1px solid#101211;
}

.list-assigned__header div .btns {
  flex: 1.4;
  display: flex;
  gap: 1px;
}

.icon:hover {
  opacity: 50%;
}

.routes {
  padding: 1px 12px;
}
.route-box {
  background: #f4f5f4;
  border-radius: 12px;
  display: flex;
  padding: 12px;
  /* padding-bottom: 0px; */
  margin: 5px 3px;

  justify-content: space-between;
  align-items: center;
  overflow: hidden;

  font-size: 14px;

  p {
    width: 80%;
  }
}

.invisible {
  visibility: hidden;
}
</style>
